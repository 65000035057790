import React, { Suspense } from "react";

import { Redirect, RouteComponentProps } from "@reach/router";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { capabilities } from "~/apps/shared/constants/enums";

import { LoadingOverlay } from "../../loading-overlay/loading-overlay";

type Props = RouteComponentProps & {
  component: React.ElementType;
};

export const EventsProtectedRoute: React.FC<Props> = ({
  component: Component,
  ...props
}) => {
  const { isLoggedIn, user } = useUser();
  const { clientConfig } = useClientConfig();

  if (!isLoggedIn) {
    return <Redirect noThrow to="/login" />;
  }

  if (!user) {
    return null;
  }

  if (!clientConfig) {
    return null;
  }

  if (
    clientConfig.isEventsVisible() &&
    user.hasCapability(capabilities.BASIC)
  ) {
    return (
      <Suspense fallback={<LoadingOverlay open />}>
        <Component {...props} />
      </Suspense>
    );
  }

  return <Redirect noThrow to="/permission" />;
};
