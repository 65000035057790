import {
  DownloadExternalApiDocResponse,
  GetClientBossesResponse,
  GetClientConfigResponse,
  GetClientInfoResponse,
  GetClientPurposeFieldOptionsResponse,
  GetUsersRequestParams,
  GetUsersResponse,
  GetUsersSimplifiedRequestParams,
  GetUsersSimplifiedResponse,
  IGetClientExternalCredentialsResponse,
  RemoveClientExternalCredentialsResponse,
  ResetClientExternalCredentialsResponse,
} from "~/apps/corporate/dtos/client.dto";
import {
  getAuthorizationHeader,
  getClientToken,
} from "~/apps/corporate/helpers/user.helper";
import * as clientParser from "~/apps/corporate/parsers/client.parser";
import * as userParser from "~/apps/corporate/parsers/user.parser";
import errorTypes from "~/apps/shared/constants/errors";
import { api } from "~/apps/shared/services/api";
import { forceFileDownload } from "~/apps/shared/utils/force-file-download";
import { AxiosError } from "axios";

export async function downloadExternalApiDoc() {
  return await api
    .request<DownloadExternalApiDocResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/external-api-docs/download`,
    })
    .then(({ data }) => {
      const { fileName, url } = data;

      forceFileDownload(url, fileName);
    });
}

export async function getActiveUsers() {
  return await getUsersRequest().then((users) =>
    users.filter((user) => !user.hidden && !!user.active),
  );
}

export async function getClientBosses(clientToken: string) {
  return await api
    .request<GetClientBossesResponse[]>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${clientToken}/bosses`,
    })
    .then(({ data }) => data.map((dto) => clientParser.parseClientBoss(dto)));
}

export async function getClientConfig(clientToken?: string) {
  return await api
    .request<GetClientConfigResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${
        clientToken ? clientToken : getClientToken()
      }/client-config`,
    })
    .then(({ data }) => clientParser.parseClientConfig(data));
}

export async function getClientExternalCredentials(): Promise<IGetClientExternalCredentialsResponse> {
  return await api
    .request<any>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/client/${getClientToken()}/external-credentials`,
    })
    .then(({ data }) => data);
}

export async function getClientInfo() {
  return await api
    .request<GetClientInfoResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/client/${getClientToken()}`,
    })
    .then(({ data }) => clientParser.parseClientInfo(data));
}

export async function getClientPurposeFieldOptions() {
  return await api
    .request<GetClientPurposeFieldOptionsResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      url: `/booking/clients/${getClientToken()}/custom-fields`,
    })
    .then(({ data }) => data.data);
}

export async function getUsers() {
  return await getUsersRequest().catch(handleError);
}

export async function getUsersRequest(params?: GetUsersRequestParams) {
  return await api
    .request<GetUsersResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params,
      url: `/booking/client/${getClientToken()}/user`,
    })
    .then(({ data }) => data.map((item) => userParser.parseClientUser(item)));
}

export async function getUsersSimplified(
  params?: GetUsersSimplifiedRequestParams,
) {
  return await api
    .request<GetUsersSimplifiedResponse>({
      headers: getAuthorizationHeader(),
      method: "GET",
      params,
      url: `/booking/client/${getClientToken()}/users-simplified`,
    })
    .then(({ data }) => userParser.parseClientUsersSimplified(data));
}

export async function removeClientExternalCredentials() {
  return await api
    .request<RemoveClientExternalCredentialsResponse>({
      headers: getAuthorizationHeader(),
      method: "DELETE",
      url: `/booking/clients/${getClientToken()}/credentials`,
    })
    .then(({ data }) => data);
}

export async function resetClientExternalCredentials() {
  return await api
    .request<ResetClientExternalCredentialsResponse>({
      headers: getAuthorizationHeader(),
      method: "POST",
      url: `/booking/clients/${getClientToken()}/reset-credentials`,
    })
    .then(({ data }) => data);
}

export async function updateClientConfig(clientConfigToken: string, data: any) {
  return await api
    .request<any>({
      data,
      headers: getAuthorizationHeader(),
      method: "PUT",
      url: `/booking/client-configs/${getClientToken()}/${clientConfigToken}`,
    })
    .then(({ data }) => data);
}

export async function updateClientInfo(data: any) {
  return await api.request<any>({
    data,
    headers: getAuthorizationHeader(),
    method: "PUT",
    url: `/booking/client/${getClientToken()}`,
  });
}

function handleError(error: AxiosError) {
  if (error.response) {
    const { data, status } = error.response;

    if (status === 400 && data.type === errorTypes.EMAIL_PASSWORD_INCORRECT) {
      throw { title: "title", description: "Usuário ou senha inválidos" };
    }

    if (status === 400 && data.type === errorTypes.ERROR_INVALID_INPUT) {
      throw { title: "title", description: "Preenchimento inválido" };
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  throw { title: "title", description: "Erro inesperado" };
}
