import {
  APPROVER_REPLACEMENT_REASON_TRANSLATION,
  APPROVER_REPLACEMENT_HISTORY_RECORD_ACTION_TRANSLATION,
} from "~/apps/shared/constants";
import { AuditAction } from "~/apps/shared/constants/enums";
import moment from "moment";

type ApproverReplacementApprover = {
  email: string;
  name: string;
  token: string;
};

export type ApproverReplacement = {
  activeStatus: ApproverReplacementActiveStatus;
  approver?: ApproverReplacementApprover;
  endDate: string;
  scheduleStatus: ApproverReplacementScheduleStatus;
  startDate: string;
  reason: {
    description: string | null;
    type: ApproverReplacementReason;
  };
  replacer: ApproverReplacementApprover;
  token: string;
};

export type ApproverReplacementHistory = {
  approverReplacementToken: string;
  history: ApproverReplacementHistoryRecord[];
};

export type ApproverReplacementHistoryRecord = {
  action: AuditAction;
  actionData?: {
    approverToken: string;
    endDate: string;
    reason: ApproverReplacementReason;
    reasonDescription: string;
    replacerToken: string;
    startDate: string;
  };
  actionOrigin: string;
  actorInfo: {
    email: string;
    name: string;
    token: string;
  };
  createdAt: string;
  entityToken: string;
  entityType: string;
  token: string;
};

class ApproverReplacementHistoryRecordModel {
  constructor(private readonly record: ApproverReplacementHistoryRecord) {}

  public getFormattedCreatedAt(): string {
    return moment(this.record.createdAt).format("DD/MM/YYYY HH:mm");
  }

  public getTranslatedAction(): string {
    return APPROVER_REPLACEMENT_HISTORY_RECORD_ACTION_TRANSLATION[
      this.record.action
    ];
  }
}

export class ApproverReplacementHistoryRecordModelFactory {
  static create(
    record: ApproverReplacementHistoryRecord,
  ): ApproverReplacementHistoryRecordModel {
    return new ApproverReplacementHistoryRecordModel(record);
  }
}

class ApproverReplacementModel {
  constructor(private readonly approverReplacement: ApproverReplacement) {}

  public getTranslatedReasonType(): string {
    return APPROVER_REPLACEMENT_REASON_TRANSLATION[
      this.approverReplacement.reason.type
    ];
  }

  public isActive(): boolean {
    return (
      this.approverReplacement.activeStatus ===
      ApproverReplacementActiveStatus.ACTIVE
    );
  }

  public isScheduled(): boolean {
    return (
      this.approverReplacement.scheduleStatus ===
      ApproverReplacementScheduleStatus.SCHEDULED
    );
  }
}

export class ApproverReplacementModelFactory {
  static create(
    approverReplacement: ApproverReplacement,
  ): ApproverReplacementModel {
    return new ApproverReplacementModel(approverReplacement);
  }
}

export enum ApproverReplacementActiveStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export type ApproverReplacementComplete = {
  approverInfo: ApproverReplacementApprover;
  replacements: ApproverReplacement[];
};

export enum ApproverReplacementReason {
  OTHER = "OTHER",
  VACATION = "VACATION",
}

export enum ApproverReplacementScheduleStatus {
  CONCLUDED = "CONCLUDED",
  END_PROCESSING = "END_PROCESSING",
  IN_PROGRESS = "IN_PROGRESS",
  SCHEDULED = "SCHEDULED",
  START_PROCESSING = "START_PROCESSING",
}

export type ReplaceableApprover = {
  approverEmail: string;
  approverName: string;
  approverToken: string;
  underReplacement: UnderReplacementStatus;
};

export enum UnderReplacementStatus {
  IN_PROGRESS = "IN_PROGRESS",
  NOT_SCHEDULED = "NOT_SCHEDULED",
  PROCESSING = "PROCESSING",
  SCHEDULED = "SCHEDULED",
}
