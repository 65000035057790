import jwtDecode from "jwt-decode";

import packageJson from "../../package.json";

export function getAccessToken() {
  return localStorage.getItem("access_token");
}

export function getUserFromAccessToken(accessToken) {
  const jwtData = jwtDecode(accessToken);

  return {
    firstName: jwtData.first_name,
    lastName: jwtData.last_name,
    clientName: jwtData.client_name,
    clientSlug: jwtData.client_slug,
    clientToken: jwtData.client_token,
    phone: jwtData.phone,
    sendSms: jwtData.send_sms,
    email: jwtData.email,
    role: jwtData.role,
    userToken: jwtData.user_token,
    guest: jwtData.guest,
    permissions: jwtData.permissions,
    plan: jwtData.plan,
    capabilities: jwtData.capabilities,
    hidden: jwtData.hidden,
    agencyToken: jwtData.agency_token,
  };
}

export function getRefreshToken() {
  const authData = getAccessToken();
  const refreshToken = authData ? authData.refresh_token : undefined;
  return refreshToken;
}

export function getUserFromLocalStorage() {
  const accessToken = getAccessToken();
  if (accessToken) {
    return getUserFromAccessToken(accessToken);
  }
}

export function getClientToken() {
  const user = getUserFromLocalStorage();
  return user ? user.clientToken : undefined;
}

export function getLoggedUserToken() {
  const user = getUserFromLocalStorage();
  return user ? user.userToken : undefined;
}

export function getAuthorizationHeader() {
  return {
    Authorization: getAccessToken(),
    ["X-Client-Version"]: packageJson.buildDate,
  };
}
