import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  autocomplete: css({
    width: "calc(256px + 16px)",
  }),
  error: css({
    padding: "0 16px 12px 16px",
  }),
  input: {
    container: css({
      alignItems: "center",
      display: "flex",
      gap: "4px",
      width: "100%",
    }),
    input: css({
      backgroundColor: "transparent",
      border: "none",
      fontFamily: theme.fonts.inter,
      fontWeight: 600,
      height: "100%",
      outline: "none",
      padding: "14px 0",
      textOverflow: "ellipsis",
      width: "100%",
    }),
    label: css({
      flexShrink: 0,
      "@media screen and (max-width: 768px)": {
        fontSize: "0.875rem",
      },
    }),
    loading: css({
      flexShrink: 0,
      marginRight: "8px",
    }),
    root: css({
      alignItems: "center",
      borderRadius: "8px",
      display: "flex",
      height: "100%",
      gap: "12px",
      paddingLeft: "16px",
      width: "100%",
    }),
  },
  item: {
    button: {
      email: css({
        color: theme.colors.gray[300],
        fontSize: "0.875rem",
      }),
      name: css({
        color: theme.colors.gray[700],
      }),
      root: ({
        isDisabled,
        isHighlighted,
      }: {
        isDisabled?: boolean;
        isHighlighted: boolean;
      }) =>
        css({
          backgroundColor: "transparent",
          border: "none",
          color: theme.colors.gray[500],
          cursor: !isDisabled ? "pointer" : "default",
          display: "flex",
          flexDirection: "column",
          lineHeight: "unset",
          opacity: !isDisabled ? 1 : 0.5,
          overflow: "hidden",
          padding: "12px 16px",
          textOverflow: "ellipsis",
          transition: "all 0.15s ease-in-out",
          whiteSpace: "nowrap",
          width: "100%",
          ":not(:disabled)": {
            ":hover": {
              backgroundColor: theme.colors.background.gray,
              color: theme.colors.gray[700],
            },
          },
        }),
    },
    item: css({
      color: theme.colors.gray[500],
      display: "flex",
      fontSize: "0.875rem",
      padding: "12px 16px",
    }),
  },
  root: ({ error }: { error?: boolean }) =>
    css({
      border: "1px solid",
      borderColor: error ? theme.colors.pink[300] : "transparent",
      borderRadius: "8px",
      color: error ? theme.colors.pink[500] : theme.colors.gray[700],
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      transition: "all 0.15s ease-in-out",
      ":hover": {
        borderColor: theme.colors.gray[100],
      },
      "@media screen and (max-width: 768px)": {
        minWidth: "unset",
        width: "100%",
      },
      "& > :first-of-type": {
        flexShrink: 0,
      },
    }),
  skeleton: css({
    alignItems: "center",
    color: theme.colors.gray[700],
    display: "flex",
    gap: "12px",
    height: "50px",
    padding: "0 16px",
    "@media screen and (max-width: 768px)": {
      minWidth: "unset",
      width: "100%",
    },
    "& > :first-of-type": {
      flexShrink: 0,
    },
  }),
};
