import { validateCNPJ } from "../../helpers/document.helper";
import { removeBlankSpaces } from "../../utils/remove-blank-spaces";
import { removeSpecialCharacters } from "../../utils/remove-special-characters";
import { MaskFunction, MaskType } from "./input-masked.types";

export const Masks = {
  CNPJ: [
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ],
  CPF: [
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    ".",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
  ],
  Cellphone: [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  CVV_3: [/\d/, /\d/, /\d/],
  CVV_4: [/\d/, /\d/, /\d/, /\d/],
  Date: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
  Datetime: [
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
    ":",
  ],
  ExpirationDate: [/\d/, /\d/, "/", /\d/, /\d/],
  MonthAndYear: [/\d/, /\d/, "/", /[1-5]/, /\d/],
  Phone: [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  Time: [/\d/, /\d/, ":", /\d/, /\d/],
  Zipcode: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
};

const getCVVMask = (value = "") => {
  return value.length <= 3 ? Masks.CVV_3 : Masks.CVV_4;
};

const getPhoneMask = (value = "") => {
  const withouSpecialChars = removeSpecialCharacters(value);
  const formattedValue = removeBlankSpaces(withouSpecialChars);

  if (formattedValue.length <= 10) {
    return Masks.Phone;
  } else {
    return Masks.Cellphone;
  }
};

export const createRGMask = (length: number) => {
  const mask: (RegExp | string)[] = [];
  const digits = Math.min(Math.max(length, 8), 16);

  mask.push(/\d/, /\d/, ".");

  let remainingDigits = digits - 2;
  while (remainingDigits > 0) {
    const groupSize = Math.min(remainingDigits, 3);

    for (let i = 0; i < groupSize; i++) {
      mask.push(/\d/);
    }

    remainingDigits -= groupSize;

    if (remainingDigits > 0) {
      mask.push(length > 8 && remainingDigits <= 2 ? "-" : ".");
    }
  }

  return mask;
};

const getRGMask = (value = "") => {
  const withouSpecialChars = removeSpecialCharacters(value);
  const formattedValue = removeBlankSpaces(withouSpecialChars);

  return createRGMask(formattedValue.length);
};

export const getMask = ({
  maskType,
  value,
}: {
  maskType: MaskType;
  value?: string;
}) => {
  if (typeof maskType !== "string") {
    return maskType;
  }

  const masks: Record<
    Exclude<MaskType, MaskFunction>,
    (string | RegExp)[] | MaskFunction
  > = {
    "cpf-cnpj": !validateCNPJ(value) ? Masks.CPF : Masks.CNPJ,
    "month-and-year": Masks.MonthAndYear,
    "phone-or-cellphone": getPhoneMask,
    cellphone: Masks.Cellphone,
    cep: Masks.Zipcode,
    cnpj: Masks.CNPJ,
    cpf: Masks.CPF,
    cvv: getCVVMask,
    date: Masks.Date,
    datetime: Masks.Datetime,
    phone: Masks.Phone,
    rg: getRGMask,
    time: Masks.Time,
  };

  return masks[maskType];
};
